.input-box {
  border: none;
  margin: 20px 0px 10px 0px;
  box-shadow: 0px 0px 6px 0px gray;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  width: 90%;
}

.input-btn {
  box-shadow: 0px 0px 6px 0px gray;
  border: none;
  padding: 5px 10px 5px 10px;
  margin: 20px 0px 10px 15px;
  border-radius: 10px;
}

.message-input-container {
  box-shadow: 0px -2px 5px 0px rgba(128, 128, 128, 0.5);
}
