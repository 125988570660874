.text-box-start {
    margin-top: 1rem;
    border-radius: 20px;
    border: none;
    max-width: 50%;
    padding: 5px 20px 5px 20px;
    color: white;
    margin-left: 10px;
}

.text-box-end {
    margin-top: 1rem;
    border-radius: 20px;
    border: none;
    max-width: 50%;
    padding: 5px 20px 5px 20px;
    color: white;
    margin-right: 10px;
}

.message-text {
    font-size: 20px;
    margin-top: 1rem;
}