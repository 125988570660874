.message-container {
  height: 80vh;
  border-radius: 10px;
}

.message-card-container {
  height: 90%;
  overflow-y: auto;
  overflow-wrap: break-word;
}
