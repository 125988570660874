.backicon {
  background-color: white;
  color: none;
  border: none;
  box-shadow: none;
  padding-right: 10px;
}
.user-edit-wahtsapp-icon {
  margin-left: 5px;
  padding-bottom: 5px;
}

.user-edit-wahtsapp-icon:hover {
  cursor: pointer;
}

.message-title-container {
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}
