.side-container {
    box-shadow: 0px 0px 6px 0px gray;
    border-radius: 10px;
    height: 80vh;
}

.fw-semibold {
    font-weight: 600;
}

.scrollarea {
    overflow-y: scroll;
    
}

.lh-tight {
    line-height: 1.25;
}