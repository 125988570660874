.lastMessage {
  max-height: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.conversation-btn {
  min-height: 80px;
  max-height: 80px;
  overflow-y: hidden;
}
.conversation-btn:hover:not(.active) {
  background-color: #e9e9eb;
}
